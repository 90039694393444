import { createTheme } from '@fluentui/react';

export const appTheme = createTheme({
  defaultFontStyle: {
    fontFamily: 'Cormorant Garamond, serif'
  },
  fonts: {
    small: {
      fontSize: '12px',
    },
    medium: {
      fontSize: '15px',
    },
    large: {
      fontSize: '20px',
      fontWeight: 'semibold',
    },
    xLarge: {
      fontSize: '22px',
      fontWeight: 'semibold',
    },
  },
});