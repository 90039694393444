import { Stack } from "@fluentui/react";
import { PageStackStyle } from "./PageStackStyle";

const FAQPage = (): JSX.Element => {
    return (
        <Stack styles={PageStackStyle}>
            <b>Do you have a registry?</b>
            <p>Due to only just recently moving into our new home we have been unable to put together a comprehensive registry of gifts. We would therefore prefer and greatly appreciate a monetary contribution towards helping furnish and making our house a home. Such gifts can be brought to the wedding where we will have a box for cards and envelopes. However, if you would prefer to give us a non-monetary gift, please reach out to us so we can help you find something appropriate to gift us.</p>
            <p></p>
            <b>Are children welcome?</b>
            <p>We will not be able to accommodate children during our wedding ceremony or reception. We thank you for understanding and hope arrangements can be made that allow you to still attend our big day.</p>
            <p></p>
            <b>What should I wear?</b>
            <p>The dress code for our wedding is semi-formal/cocktail attire. Example attire includes cocktail/formal dresses, suit with tie, and sport coat with tie.</p>
            <p></p>
            <b>How should I get to and from JM Cellars?</b>
            <p>For guests staying at the Hilton Garden Inn Redmond Town Center, there will be a complimentary shuttle. More details on shuttle timing will be available closer to the day of the wedding. For all other guests please consider using a ride share service, or if you do decide to drive, then please consider carpooling with as many people as possible. There will be limited valet parking available at the venue.</p>
            <p></p>
            <b>Do I have to be vaccinated against COVID-19 to attend?</b>
            <p>Yes, all wedding guests and participants will be required to be fully vaccinated against COVID-19. Please be prepared to show proof of vaccination on the day of the wedding.</p>
            <p></p>
            <b>Will there be a reception/dinner after the ceremony?</b>
            <p>Yes, the ceremony will be followed by a cocktail hour, dinner, and then dancing, all held at JM Cellars.</p>
            <p></p>
            <b>Do I need to bring money for drinks?</b>
            <p>No, all drinks will be provided at no cost to you.</p>
            <p></p>
        </Stack>
    );
};

export default FAQPage;
