import '../../App.css';

import { Stack } from "@fluentui/react";
import { PageStackStyle } from "./PageStackStyle";

const HomePage = (): JSX.Element => {
    return (
        <Stack styles={PageStackStyle} tokens={{ childrenGap: 10 }}>
            <div className="container">
                <iframe className="video" src="https://www.youtube.com/embed/GaYICzhfkvg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <p>Greetings family and friends! For those who were able to attend, thank you again for being with us on our special day. For those who weren't able to make it, please know we were thinking of you. We feel so blessed to be surrounded by such an amazing community of friends and family.</p>
            <p>We'd like to go ahead and share some photos and videos of our wedding with you. Above you can find our wedding highlight video, on the Photos tab you can find wedding photos, and on the Ceremony tab you can find a video of the ceremony. If you'd like any full size copies of photos please reach out to us. Also, if you'd like copies of your photo booth pictures we have those as well.</p>
            <p>Thank you again for being in our lives! We hope to see all of you again soon.</p>
        </Stack>
    );
};

export default HomePage;
