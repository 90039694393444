import { useCallback, useRef, useState } from "react";
import { IStackStyles, IStackTokens, ProgressIndicator, Stack, TextField } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { PageStackStyle } from "./PageStackStyle";
import { appTheme } from "../../AppTheme";
import { UserId } from "../../Telemetry/Telemetry";

interface IRsvpData {
    attending?: boolean;
    guestNameOne?: string;
    guestNameTwo?: string;
    foodRestrictions?: string;
    songRequests?: string;
    questionsOrComments?: string;
    /**
     * Not actually stored in the RSVP blobs.
     * Populated when getting data, deleted when setting.
     */
    lastModified?: string;
}

const buttonStackStyle: Partial<IStackStyles> = { root: { textAlign: "center", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", gridColumnGap: "16px", width: "fit-content", padding: "12px 0" } }
const formStackTokens: IStackTokens = { childrenGap: 10 };

const RsvpPage = (): JSX.Element => {
    const [rsvpCode, setRsvpCode] = useState<string>("");
    const [isVerifyingRsvp, setIsVerifyingRsvp] = useState<boolean>(false);
    const [isRsvpVerified, setRsvpVerified] = useState<boolean>(false);
    const [decision, setDecision] = useState<boolean>();
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [formError, setFormError] = useState<string>();

    const [initRsvpData, setInitRsvpData] = useState<IRsvpData>();

    const rsvpObject = useRef<IRsvpData>({});

    const showSingleGuest = rsvpCode === "2496" || rsvpCode === "7331";

    function ContinueButton() {
        return (
            <Stack horizontal styles={buttonStackStyle}>
                <PrimaryButton theme={appTheme} text="Continue" onClick={_onContinueClicked} disabled={rsvpCode === "" || isVerifyingRsvp} />
            </Stack>
        )
    }

    function FormErrorMessage() {
        return <div>{formError}</div>
    }

    function VerifyingRsvpIndicator() {
        return (
            isVerifyingRsvp ? <ProgressIndicator theme={appTheme} description="Verifying Your RSVP Code..." /> : <></>
        )
    }

    function GuestForm() {
        return (
            <Stack styles={PageStackStyle} tokens={formStackTokens}>
                <TextField theme={appTheme} defaultValue={initRsvpData?.guestNameOne} placeholder={ showSingleGuest ? "Guest name" : "Guest 1 name"} onChange={(_event: any, newValue: any) => fieldChangeHandler("guestNameOne", newValue)} required={true} />
                {
                    showSingleGuest ?
                        <></> :
                        <TextField theme={appTheme} defaultValue={initRsvpData?.guestNameTwo} placeholder={"Guest 2 name"} onChange={(_event: any, newValue: any) => fieldChangeHandler("guestNameTwo", newValue)} />
                }
                <div>
                    <p>Any food restrictions?</p>
                    <TextField theme={appTheme} defaultValue={initRsvpData?.foodRestrictions} placeholder={"Vegetarian, vegan, allergies, etc."} onChange={(_event: any, newValue: any) => fieldChangeHandler("foodRestrictions", newValue)} />
                </div>
                <div>
                    <p>Any song requests?</p>
                    <TextField theme={appTheme} defaultValue={initRsvpData?.songRequests} onChange={(_event: any, newValue: any) => fieldChangeHandler("songRequests", newValue)} />
                </div>
                <div>
                    <p>Questions or comments?</p>
                    <TextField theme={appTheme} defaultValue={initRsvpData?.questionsOrComments} multiline rows={5} onChange={(_event: any, newValue: any) => fieldChangeHandler("questionsOrComments", newValue)} />
                </div>
            </Stack>
        );
    }
    function SubmitButton() {
        return (
            <Stack horizontal styles={buttonStackStyle}>
                <PrimaryButton theme={appTheme} text="Submit" onClick={_onSubmitClicked} disabled={decision === undefined} />
            </Stack>
        )
    }

    function AcceptedInviteMessage() {
        return (
            <Stack styles={PageStackStyle} tokens={formStackTokens}>
                <p>Thank you for RSVPing! We can't wait to see you on our big day! If you need to edit your RSVP, simply return to this page and re-enter your RSVP code. For information on registry, accommodations, and more, see the other tabs. Please reach out if you have any other questions.</p>
            </Stack>
        )
    }

    function RejectedInviteMessage() {
        return (
            <Stack styles={PageStackStyle} tokens={formStackTokens}>
                <p>Thank you for RSVPing! We are sorry you will not be able to attend our wedding. If you need to edit your RSVP, simply return to this page and re-enter your RSVP code.</p>
            </Stack>
        )
    }

    const fieldChangeHandler = useCallback(
        (name: string, fieldValue: string): void => {
            rsvpObject.current = { ...rsvpObject.current, [name]: fieldValue };
        }, [],
    );

    const _onChangeRsvpCode = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setRsvpCode(newValue || '');
        }, [],
    );

    function _onContinueClicked() {
        // Clear any existing form errors
        setFormError(undefined);
        if (rsvpCode !== "") {
            setIsVerifyingRsvp(true);
            const options: RequestInit = {
                method: "GET",
                headers: [
                    ["user-id", UserId]
                ]
            };
            const confirmRsvpUrl = `https://spaghettianddumplings.azurewebsites.net/api/confirm-code?code=${rsvpCode}`;
            fetch(confirmRsvpUrl, options).then((response) => {
                if (response.ok) {
                    setRsvpVerified(true);
                    response.json().then((rsvpData) => {
                        _processRsvpData(rsvpData);
                    });
                } else {
                    setFormError("Could not verify your RSVP code. Please try again. If you think this was a mistake, please contact us.");
                }
            })
                .catch(fail => {
                    setFormError("Oops, something went wrong with verifying your RSVP code. Please try again.");
                })
                .finally(() => {
                    setIsVerifyingRsvp(false);
                });
        }
    }

    function _onSubmitClicked() {
        const options: RequestInit = {
            method: "PUT",
            body: JSON.stringify(rsvpObject.current),
            headers: [
                ["user-id", UserId]
            ]
        };
        const submitRsvpUrl = `https://spaghettianddumplings.azurewebsites.net/api/rsvp?code=${rsvpCode}`;
        fetch(submitRsvpUrl, options).then((response) => {
            if (response.ok) {
                setSubmitted(true);
            } else {
                setFormError("Oops, something went wrong with submitting your response. Please try again. If the issue persists, please contact us.");
            }
        })
            .catch(fail => {
                setFormError("Oops, something went wrong with submitting your response. Please try again. If the issue persists, please contact us.");
            });
    }

    function _onDecisionButtonClicked(hasAccepted: boolean) {
        setDecision(hasAccepted);
        rsvpObject.current = { ...rsvpObject.current, attending: hasAccepted };
    }

    function _processRsvpData(data: IRsvpData) {
        rsvpObject.current = data;
        setDecision(data.attending);
        setInitRsvpData(data);
    }

    const yesDecision = decision === true;

    return (
        <Stack styles={PageStackStyle} tokens={formStackTokens}>
            {
                !isSubmitted ?
                    <div>
                        <p>Please enter the RSVP code from your invitation, verify guest names, and answer any additional questions.</p>
                        {
                            !isRsvpVerified ?
                                <div>
                                    <TextField theme={appTheme} placeholder={"Enter your RSVP Code"} required={true} onChange={_onChangeRsvpCode} />
                                    <ContinueButton /> </div> : <></>
                        }
                        <VerifyingRsvpIndicator />
                        {
                            isRsvpVerified && !isVerifyingRsvp ?
                                <div>
                                    <p><span>Will you be attending our wedding on </span><b>Sunday August 28 2022?</b></p>
                                    <Stack horizontal styles={buttonStackStyle}>
                                        <DefaultButton theme={appTheme} text="Yes" checked={yesDecision} allowDisabledFocus onClick={() => _onDecisionButtonClicked(true)} />
                                        <DefaultButton theme={appTheme} text="No" checked={decision === false} allowDisabledFocus onClick={() => _onDecisionButtonClicked(false)} />
                                    </Stack>
                                    {
                                        yesDecision ? <GuestForm /> : <></>
                                    }
                                    <SubmitButton />
                                </div> : <></>
                        }
                        {
                            formError !== undefined ?
                                <FormErrorMessage /> : <></>
                        }
                    </div> :
                    // Submitted form
                    yesDecision ?
                        <AcceptedInviteMessage /> : <RejectedInviteMessage />
            }

        </Stack>
    );
};

export default RsvpPage;
