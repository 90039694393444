import './../../src/App.css';
import HeaderImg from "../images/HeaderImg.png";

const Header = (): JSX.Element => {
    return (
        <header>
            <h1 className="AppHeader">Erica & Matthew</h1>
            <h2 className="AppSubHeader">Sunday August 28, 2022 - Woodinville WA</h2>
            <img className="HeaderImage" src={HeaderImg} alt="Header decorative leaf"></img>
        </header>
    );
};

export default Header;
