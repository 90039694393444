import './App.css';
import { appTheme } from './AppTheme';
import Header from './components/Header';
import HomePage from './components/Pages/HomePage';
import WeddingPage from './components/Pages/WeddingPage';
import RsvpPage from './components/Pages/RsvpPage';
import TravelAccommodationsPage from './components/Pages/TravelAccommodationsPage';
import FAQPage from './components/Pages/FAQPage';
import ThingsToDoPage from './components/Pages/ThingsToDoPage';
import PhotosPage from './components/Pages/PhotosPage';

import { trackEvent } from "./Telemetry/Telemetry";

import { IconButton, Pivot, PivotItem } from '@fluentui/react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CeremonyPage from './components/Pages/CeremonyPage';
initializeIcons();

function App() {

  const [activeTab, setActiveTab] = useState("home");
  /** Hook to determine whether or not the page is displayed on desktop/large screen or mobile/small screen */
  const isBigScreen = useMediaQuery({ query: '(min-width: 1000px)' });

  /** Enum representing the pages of the website */
  const enum TabbedPage {
    Home = "home",
    Photos = "photos",
    Ceremony = "ceremony",
    Wedding = "wedding",
    RSVP = "rsvp",
    TravelAndAccommodations = "travelandaccommodations",
    FAQ = "faq",
    ThingsToDo = "thingstodo",
  }

  interface PageProps {
    selectedTab: string;
  }

  /** Component that shows the selected page */
  const DisplayedPage = ({ selectedTab }: PageProps): JSX.Element => {
    trackEvent({ name: "ChangeTab", properties: { selectedTab: selectedTab } });
    let page: JSX.Element;
    switch (selectedTab) {
      case TabbedPage.Wedding:
        page = <WeddingPage />
        break;
      case TabbedPage.RSVP:
        page = <RsvpPage />
        break;
      case TabbedPage.TravelAndAccommodations:
        page = <TravelAccommodationsPage />
        break;
      case TabbedPage.FAQ:
        page = <FAQPage />
        break;
      case TabbedPage.ThingsToDo:
        page = <ThingsToDoPage />
        break;
      case TabbedPage.Photos:
        page = <PhotosPage />
        break;
      case TabbedPage.Ceremony:
        page = <CeremonyPage />
        break;
      default:
        page = <HomePage />
    }
    return page;
  }

  return (
    <div className="App">
      <Header />
      {!isBigScreen ?
        /** Show hamburger menu if it is a small screen, otherwise show full tabs */
        <div>
          <IconButton
            className="NavIcon"
            theme={appTheme}
            iconProps={{ iconName: 'CollapseMenu' }}
            title="Menu"
            ariaLabel="Menu"
            menuProps={{
              items: [
                {
                  key: TabbedPage.Home,
                  text: 'Home',
                  className: "NavMenuItem",
                  onClick: () => { setActiveTab(TabbedPage.Home); }
                },
                {
                  key: TabbedPage.Photos,
                  text: 'Photos',
                  className: "NavMenuItem",
                  onClick: () => { setActiveTab(TabbedPage.Photos); }
                },
                {
                  key: TabbedPage.Ceremony,
                  text: 'Ceremony',
                  className: "NavMenuItem",
                  onClick: () => { setActiveTab(TabbedPage.Ceremony); }
                },
                {
                  key: TabbedPage.Wedding,
                  text: 'The Wedding',
                  className: "NavMenuItem",
                  onClick: () => { setActiveTab(TabbedPage.Wedding); }
                },
                {
                  key: TabbedPage.RSVP,
                  text: 'RSVP',
                  className: "NavMenuItem",
                  onClick: () => { setActiveTab(TabbedPage.RSVP); }
                },
                {
                  key: TabbedPage.TravelAndAccommodations,
                  text: 'Travel & Accommodations',
                  className: "NavMenuItem",
                  onClick: () => { setActiveTab(TabbedPage.TravelAndAccommodations); }
                },
                {
                  key: TabbedPage.FAQ,
                  text: 'Registry & FAQ',
                  className: "NavMenuItem",
                  onClick: () => { setActiveTab(TabbedPage.FAQ); }
                },
                {
                  key: TabbedPage.ThingsToDo,
                  text: 'Things to Do',
                  className: "NavMenuItem",
                  onClick: () => { setActiveTab(TabbedPage.ThingsToDo); }
                },
              ],
              directionalHintFixed: true,
            }}
          />
        </div>
        :
        <Pivot
          selectedKey={activeTab}
          onLinkClick={(item?: PivotItem) => {
            setActiveTab(item?.props?.itemKey || TabbedPage.Home);
          }}
          theme={appTheme}
          aria-label="Menu navigation"
        >
          <PivotItem headerText="Home" headerButtonProps={{ 'data-order': 1 }} itemKey={TabbedPage.Home} />
          <PivotItem headerText="Photos" itemKey={TabbedPage.Photos} />
          <PivotItem headerText="Ceremony" itemKey={TabbedPage.Ceremony} />
          <PivotItem headerText="Wedding" itemKey={TabbedPage.Wedding} />
          <PivotItem headerText="RSVP" itemKey={TabbedPage.RSVP} />
          <PivotItem headerText="Travel & Accommodations" itemKey={TabbedPage.TravelAndAccommodations} />
          <PivotItem headerText="Registry & FAQ" itemKey={TabbedPage.FAQ} />
          <PivotItem headerText="Things to Do" itemKey={TabbedPage.ThingsToDo} />
        </Pivot>
      }
      <DisplayedPage selectedTab={activeTab} />
    </div>
  );
}

export default App;
