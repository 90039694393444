import { ApplicationInsights, IEventTelemetry } from "@microsoft/applicationinsights-web";

// this file is not tracked by git, if you don't have this file, you'll need to make it!
import { ConnectionString } from "./ConnectionString";

const AppInsightsClient = new ApplicationInsights({
    config: {
        connectionString: ConnectionString
    }
});
AppInsightsClient.loadAppInsights();

export const UserId: string = AppInsightsClient.context.user.id;

export function trackEvent(telemetry: IEventTelemetry) {
    AppInsightsClient.trackEvent(telemetry);
}