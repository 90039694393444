import '../../App.css';

import { IStackStyles, Stack } from "@fluentui/react";
import SeattleSkyline from "../../images/SeattleSkyline.jpg";
import { useMediaQuery } from 'react-responsive';
import { PageStackStyle } from './PageStackStyle';


const ThingsToDoPage = (): JSX.Element => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1000px)' });
    // If large screen, show the picture next to the text, otherwise show it below
    const stackStyle: Partial<IStackStyles> = isBigScreen ?
        { root: { width: "900px", display: "grid", gridTemplateColumns: "50% 50%", margin: "auto", paddingTop: "20px", textAlign: "left" } }
        : PageStackStyle;

    return (
        <Stack styles={stackStyle}>
            <div>
                <p>We hope that attending our wedding isn’t the only thing you get to do while visiting Seattle. There are so many things to do and see, both within the city itself and nearby.</p>
                <p>We’ve prepared a guide of things to do, attractions, and places to eat in the Seattle area. If you have any questions about the things in the guide, or would like more suggestions, feel free to reach out to us.</p>
                <a href="https://www.spaghettianddumplings.com/files/SeattleGuide.pdf" target="_blank" rel="noreferrer">Click here to view our guide</a>
            </div>
            <img className="Photo" src={SeattleSkyline} alt={"JM Cellars venue"} />
        </Stack>

    );
};

export default ThingsToDoPage;
