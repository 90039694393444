import '../../App.css';

import { Stack } from "@fluentui/react";
import { PageStackStyle } from "./PageStackStyle";

const CeremonyPage = (): JSX.Element => {
    return (
        <Stack styles={PageStackStyle} tokens={{ childrenGap: 10 }}>
            <div className="container">
                <iframe className="video" src="https://www.youtube.com/embed/1XIR87LIeXk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </Stack>
    );
};

export default CeremonyPage;
