import "../../App.css";

import { IStackStyles, IStackTokens, ProgressIndicator, Stack, TextField } from "@fluentui/react";
import { PageStackStyle } from "./PageStackStyle";
import { useCallback, useState } from "react";
import { appTheme } from "../../AppTheme";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

type PhotoData = { url: string, width: number, height: number, };

const buttonStackStyle: Partial<IStackStyles> = { root: { textAlign: "center", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", gridColumnGap: "16px", width: "fit-content", padding: "12px 0" } }
const formStackTokens: IStackTokens = { childrenGap: 10 };

function calculateAspectRatio(height: number, width: number) {
    if (height > width) {
        return { width: 1, height: height / width }
    } else {
        return { height: 1, width: width / height }
    }
}

const PhotosPage = (): JSX.Element => {
    const [photosPassword, setPhotosPassword] = useState<string>("");
    const [photoData, setPhotoData] = useState<PhotoData[] | null>();
    const [isVerifyingPassword, setIsVerifyingPassword] = useState<boolean>(false);
    const [formError, setFormError] = useState<string>();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    function VerifyingPasswordIndicator() {
        return (
            isVerifyingPassword ? <ProgressIndicator theme={appTheme} description="Verifying Password..." /> : <></>
        )
    }

    function SubmitButton() {
        return (
            <Stack horizontal styles={buttonStackStyle}>
                <PrimaryButton theme={appTheme} text="Continue" onClick={_onSubmitClicked} disabled={photosPassword === "" || isVerifyingPassword} />
            </Stack>
        )
    }

    function FormErrorMessage() {
        return <div>{formError}</div>
    }

    function PhotoGallery() {
        const photos = (photoData ?? [])
            .sort((a, b) => {
                const aPathName = (new URL(a.url)).pathname;
                const bPathName = (new URL(b.url)).pathname;
                const aIdxStr = aPathName.split("-")[1].split(".")[0];
                const bIdxStr = bPathName.split("-")[1].split(".")[0];
                return Number.parseInt(aIdxStr) - Number.parseInt(bIdxStr);
            })
            .map((data) => {
                const aspectRatio = calculateAspectRatio(data.height, data.width);
                return {
                    src: data.url,
                    height: aspectRatio.height,
                    width: aspectRatio.width
                }
            });
        return (
            <div>
                <Gallery photos={photos} onClick={_openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={_closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    source: x.src
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        );
    }

    const _openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const _closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const _onChangePassword = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setPhotosPassword(newValue || "");
        }, [],
    );

    function _onSubmitClicked() {
        // Clear any existing form errors
        setFormError(undefined);
        if (photosPassword !== "") {
            setIsVerifyingPassword(true);
            const options: RequestInit = {
                method: "GET",
                headers: []
            };
            const getPhotosUrl = `https://spaghettianddumplings.azurewebsites.net/api/get-photos?password=${photosPassword}`;
            fetch(getPhotosUrl, options).then(async (response) => {
                if (response.ok) {
                    response.json().then((photoData) => {
                        setPhotoData(photoData);
                    });
                } else {
                    setFormError("Invalid password.");
                }
            })
                .catch(fail => {
                    setFormError("Invalid password.");
                })
                .finally(() => {
                    setIsVerifyingPassword(false);
                });
        }
    }

    return (
        <Stack styles={PageStackStyle} tokens={formStackTokens}>
            {
                !photoData || photoData.length === 0 ?
                    <div>
                        <p>Please enter the password.</p>
                        {
                            <div>
                                <TextField theme={appTheme} placeholder={""} required={true} onChange={_onChangePassword} />
                                <SubmitButton />
                            </div>
                        }
                        <VerifyingPasswordIndicator />
                        {
                            formError !== undefined ?
                                <FormErrorMessage /> : <></>
                        }
                    </div> :
                    PhotoGallery()
            }

        </Stack>
    );
};

export default PhotosPage;
