import '../../App.css';

import { IStackStyles, Stack } from "@fluentui/react";
import { useMediaQuery } from 'react-responsive';
import JmCellarsWinery from "../../images/JmCellarsWinery.jpg";

const titleTextStyle: React.CSSProperties = { fontSize: "20px", fontWeight: "bold" };
const infoTextStyle: React.CSSProperties = { marginTop: "-10px" };
const WeddingPage = (): JSX.Element => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1000px)' });

    const stackStyleWideGap: Partial<IStackStyles> = { root: { display: "grid", gridTemplateColumns: "26% 20% 8% 20% 26%", margin: "auto", padding: "20px 0", textAlign: "center", fontFamily: 'Cormorant SC, serif' } }
    const stackStyle: Partial<IStackStyles> = { root: { gridColumnGap: "20px", display: "grid", gridTemplateColumns: "50% 50%", margin: "auto", padding: "20px 0", textAlign: "center", fontFamily: 'Cormorant SC, serif' } }

    return (
        <div style={{ marginBottom: "50px" }}>
            <img className="Photo" src={JmCellarsWinery} alt={"JM Cellars venue"} />
            <Stack styles={isBigScreen ? stackStyleWideGap : {}}>
                <div></div>
                <div>
                    <h1 className="DetailsSubHeader">When</h1>
                    <p style={titleTextStyle}>Sunday, August 28, 2022</p>
                    <p style={infoTextStyle}>6:30 in the evening</p>
                </div>
                <div></div>
                <div>
                    <h1 className="DetailsSubHeader">Where</h1>
                    <p style={titleTextStyle}>JM Cellars</p>
                    <p style={infoTextStyle}>14404 137th Pl NE, Woodinville, WA 98072</p>
                </div>
                <div></div>
            </Stack>
            <h1 className="DetailsSubHeader">Schedule</h1>
            <Stack styles={stackStyle}>
                <div style={{ textAlign: "right" }}>
                    <p style={titleTextStyle}>First Shuttle to JM Cellars</p>
                    <p style={infoTextStyle}>All interested</p>

                    <p style={titleTextStyle}>Second Shuttle to JM Cellars</p>
                    <p style={infoTextStyle}>All interested</p>

                    <p style={titleTextStyle}>Ceremony and reception</p>
                    <p style={infoTextStyle}>Earliest arrival time 6:00pm</p>

                    <p style={titleTextStyle}>Shuttle from ceremony</p>
                    <p style={infoTextStyle}>All interested</p>
                </div>
                <div style={{ textAlign: "left" }}>
                    <p style={titleTextStyle}>5:30pm</p>
                    <p style={infoTextStyle}>Hilton Garden Inn Redmond Town Center</p>

                    <p style={titleTextStyle}>6:05pm</p>
                    <p style={infoTextStyle}>Hilton Garden Inn Redmond Town Center</p>

                    <p style={titleTextStyle}>6:25pm</p>
                    <p style={infoTextStyle}>JM Cellars</p>

                    <p style={titleTextStyle}>11:30pm</p>
                    <p style={infoTextStyle}>Hilton Garden Inn Redmond Town Center</p>
                </div>
            </Stack>
        </div>
    );
};

export default WeddingPage;
