import { Stack } from "@fluentui/react";
import { PageStackStyle } from "./PageStackStyle";

const TravelAccommodationsPage = (): JSX.Element => {
    return (
        <Stack styles={PageStackStyle}>
            <p>For our out-of-town guests, please consider staying at Hilton Garden Inn Redmond Town Center. You are not required to stay there, however, a complimentary shuttle to and from the wedding will be available to guests who stay at this hotel.</p>
            <p>We have also secured a discounted rate for the Hilton Garden Inn Redmond Town Center. You can use the following link to take advantage of the discounted rate.</p>
            <p style={{fontWeight: "bold"}}>The following discount offer expires on August 7</p>
            <a style={{ fontFamily: "Cormorant SC, serif", fontWeight: "bold" }}
                href="https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=LKEGIGI&groupCode=LINRAY&arrivaldate=2022-08-27&departuredate=2022-08-29&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT" target="_blank" rel="noreferrer">
                Click here to book the hotel at a discounted rate, Saturday through Monday.
            </a>
            <br></br>
            <p>If you would like to make a reservation for more than Saturday through Monday then please contact the hotel for assistance.</p>
            <br></br>
            <div style={{ fontFamily: "Cormorant SC, serif", fontWeight: "bold" }}>
                <p>Hilton Garden Inn Redmond Town Center</p>
                <p>16630 Redmond Way, Redmond, WA 98052, United States</p>
                <p>(425) 448-7660</p>
                <a href="https://goo.gl/maps/vgbKKV4i3hhBGz7h7" target="_blank" rel="noreferrer">Directions</a>
            </div>
        </Stack>
    );
};

export default TravelAccommodationsPage;
